<template>
	<div class="container-fluid">
		<div v-if="$auth.can('formalizacion','formalizacion_notario') || $auth.can('formalizacion','formalizacion_evaluador')">
			<br />
			<div class="col-sm-12 text-right">
				<h1>Formalización</h1>
			</div>
			<br />
			<div class="d-flex flex-wrap"> 
				
				<div v-if="($auth.can('formalizacion','formalizacion_notario') || $auth.can('formalizacion','formalizacion_evaluador')) && !$auth.can('formalizacion','formalizacion_administracion')" class="col-md-4">
					<div class="bpb-card">
						<div class="card-body">
							<div class="text-center">
								<img src="https://static.bienparabien.com/constelacion/img/notarios.png" alt="Notarios" width="80px" />
							</div>
							<h4 class="card-title">Solicitudes</h4>
							<p class="card-subtitle text-muted">Administracion de solicitudes</p>
							<router-link :to="{name: 'formalizacion_v2_usuarios_solicitudes'}" class="btn complementary-btn">Acceder</router-link>
						</div>
					</div>
				</div>

			</div>

		</div>
		<div v-if="$auth.can('formalizacion','listar_empresas') || $auth.can('formalizacion','listar_usuarios')">
			<div class="d-flex flex-wrap">

				<div v-if="$auth.can('formalizacion','formalizacion_administracion')" class="col-md-4">
					<div class="bpb-card">
						<div class="card-body">
							<div class="text-center">
								<img src="https://static.bienparabien.com/constelacion/img/notarios.png" alt="Notarios" width="80px" />
							</div>
							<h4 class="card-title">Solicitudes</h4>
							<p class="card-subtitle text-muted">Administracion de solicitudes</p>
							<router-link :to="{name: 'formalizacion_V2_administracion_solicitudes'}" class="btn complementary-btn">Acceder</router-link>
						</div>
					</div>
				</div>

				<div v-if="$auth.can('formalizacion','*')" class="col-md-4">
					<div class="bpb-card">
						<div class="card-body">
							<div class="text-center">
								<img src="https://static.bienparabien.com/constelacion/img/notarios.png" alt="Notarios" width="80px" />
							</div>
							<h4 class="card-title">Etapas</h4>
							<p class="card-subtitle text-muted">Administracion de etapas</p>
							<router-link :to="{name: 'formalizacion_V2_administracion_etapas'}" class="btn complementary-btn">Acceder</router-link>
						</div>
					</div>
				</div>

				<div v-if="$auth.can('formalizacion','formalizacion_administracion')" class="col-md-4">
					<div class="bpb-card">
						<div class="card-body">
							<div class="text-center">
								<img src="https://static.bienparabien.com/constelacion/img/notarios.png" alt="Notarios" width="80px" />
							</div>
							<h4 class="card-title">Catálogos</h4>
							<p class="card-subtitle text-muted">Administracion de catálogos</p>
							<router-link :to="{name: 'formalizacion_V2_administracion_catalogos'}" class="btn complementary-btn">Acceder</router-link>
						</div>
					</div>
				</div>

				<div v-if="$auth.can('formalizacion','*')" class="col-md-4">
					<div class="bpb-card">
						<div class="card-body">
							<div class="text-center">
								<img src="https://static.bienparabien.com/constelacion/img/notarios.png" alt="Notarios" width="80px" />
							</div>
							<h4 class="card-title">Configuración</h4>
							<p class="card-subtitle text-muted">Administracion de Configuración</p>
							<router-link :to="{name: 'formalizacion_V2_administracion_configuracion'}" class="btn complementary-btn">Acceder</router-link>
						</div>
					</div>
				</div>
				
				<div v-if="$auth.can('formalizacion','formalizacion_administracion')" class="col-md-4">
					<div class="bpb-card">
						<div class="card-body">
							<div class="text-center">
								<img src="https://static.bienparabien.com/constelacion/img/formalizacion_usuarios.png" alt="Usuarios" width="80px" />
							</div>
							<h4 class="card-title">Usuarios</h4>
							<p class="card-subtitle text-muted">Administración de usuarios</p>
							<router-link :to="{name: 'formalizacion_administracion_usuarios'}" class="btn complementary-btn">Acceder</router-link>
						</div>
					</div>
				</div>
				
				<div v-if="$auth.can('formalizacion','formalizacion_empresas')" class="col-md-4">
					<div class="bpb-card">
						<div class="card-body">
							<div class="text-center">
								<img src="https://static.bienparabien.com/constelacion/img/empresas.svg" alt="Empresas" width="80px" />
							</div>
							<h4 class="card-title">Empresas</h4>
							<p class="card-subtitle text-muted">Administración de empresas</p>
							<router-link :to="{name: 'formalizacion_administracion_empresas'}" class="btn complementary-btn">Acceder</router-link>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		mounted: function() {
			let menu = [];

			if ((this.$auth.can('formalizacion','formalizacion_notario') || this.$auth.can('formalizacion','formalizacion_evaluador')) && !this.$auth.can('formalizacion','formalizacion_administracion')) {
				menu.push({title: "Formalización"})
				menu[0].formalizacion_v2_usuarios_solicitudes = 'Solicitudes';
			}

			if (this.$auth.can('formalizacion','formalizacion_administracion')) {
				menu.push({title:'Formalización administración'});
				
				if (this.$auth.can('formalizacion','listar_solicitudes'))
					menu[0].formalizacion_V2_administracion_solicitudes = 'Solicitudes';

				if (this.$auth.can('formalizacion','crear_etapas'))
					menu[0].formalizacion_V2_administracion_etapas = 'Etapas';

				if (this.$auth.can('formalizacion','crear_etapas'))
					menu[0].formalizacion_V2_administracion_catalogos = 'Catálogos';

				if (this.$auth.can('formalizacion','crear_etapas'))
					menu[0].formalizacion_V2_administracion_configuracion = 'Configuración';
				
				if (this.$auth.can('formalizacion','listar_empresas'))
					menu[0].formalizacion_administracion_empresas = 'Empresas';

				if (this.$auth.can('formalizacion','listar_usuarios'))
					menu[0].formalizacion_administracion_usuarios = 'Usuarios';
			}

			this.$store.commit('setMenu', menu);
		}
	}
</script>